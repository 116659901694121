import React from 'react'
import Image from 'next/image'
import { Clickable } from '../bases/Clickable'
import { VStack } from '../bases/VStack'
import { HStack } from '../bases/HStack'
import { Text } from '../bases/Text'
import { Absolute } from '../bases/Absolute'
import { Center } from '../bases/Center'
import IconArrowRight from '@/public/images/iconArrowRight.png'
import QuestCompleted from '@/public/images/questCompleted.png'
import { QuestGauge } from './QuestGauge'

export type QuestState = 'Default' | 'Completed' | 'NO_JOIN' | 'NO_QUEST'

export enum QuestTabType {
  ONWARD = 'Onward',
  EVENT = 'Event',
  PREMIUM = 'Premium',
  VIP = 'VIP',
}

type QuestInfo = {
  title: string
  allProgressGoal: number
  allProgress: number
  text: string
  progressGoal: number
  progress: number
}

export type Props = {
  state: QuestState
  type: QuestTabType
  questInfo?: QuestInfo
  onClick?: () => void
}

export const questBorderColorClass = {
  [QuestTabType.ONWARD]: 'border-quest-onward-main',
  [QuestTabType.EVENT]: 'border-quest-event-main',
  [QuestTabType.PREMIUM]: 'border-quest-premium-main',
  [QuestTabType.VIP]: 'border-quest-vip-main',
}

export const questBackGroundColorClass = {
  [QuestTabType.ONWARD]: 'bg-quest-onward-main/50',
  [QuestTabType.EVENT]: 'bg-quest-event-main/50',
  [QuestTabType.PREMIUM]: 'bg-quest-premium-main/50',
  [QuestTabType.VIP]: 'bg-quest-vip-main/50',
}

export const QuestCell = (props: Props) => {
  const isComplete = props.state === 'Completed'
  return (
    <Clickable
      disabled={props.state === 'NO_QUEST'}
      onClick={props.onClick}
      className={`min-h-[94px]  min-w-[251px] rounded-lg p-2 ${questBackGroundColorClass[props.type]} ${
        isComplete ? 'border-2 border-akiverse-yellow' : ''
      }`}
    >
      {(props.state === 'Default' || props.state === 'Completed') && props.questInfo ? (
        <DefaultQuestCell {...props.questInfo} />
      ) : props.state === 'NO_JOIN' ? (
        <HStack className="h-full w-full items-center justify-center">
          <Text className="text-sm font-semibold text-neon-blue">Join the next Quest Chain!!</Text>
          <Center className="ml-2 h-5 w-5">
            <Image src={IconArrowRight} alt="arrowRight" />
          </Center>
        </HStack>
      ) : (
        <HStack className="h-full w-full items-center justify-center">
          <Text className="whitespace-pre-wrap text-sm font-semibold text-neon-blue">
            There are no Quest Chains to join.{'\n'}Stay tuned for the next addition!
          </Text>
        </HStack>
      )}
      {isComplete && <CompletedView />}
    </Clickable>
  )
}

const DefaultQuestCell = (props: QuestInfo) => {
  const { title, allProgressGoal, allProgress, text, progressGoal, progress } = props
  return (
    <>
      <VStack className="justify-between gap-y-1">
        <HStack className="items-center">
          <Text className="text-left text-lg font-bold text-white">{title}</Text>
          <Text className="ml-2 text-sm font-bold text-neon-blue">
            {allProgress >= allProgressGoal ? allProgressGoal : allProgress + 1}/{allProgressGoal}
          </Text>
        </HStack>
        <HStack className="h-[34px] items-center justify-between text-left">
          <Text className="text-sm text-white">{text}</Text>
        </HStack>
        <div className={`relative flex h-full flex-row items-center justify-center`}>
          <QuestGauge progressGoal={progressGoal} progress={progress} className="w-full" />
        </div>
      </VStack>
    </>
  )
}

const CompletedView = () => (
  <Absolute className="left-0 top-0 h-full w-full rounded-lg bg-black/70">
    <Center className="w-full">
      <Image src={QuestCompleted} alt="clear" />
    </Center>
  </Absolute>
)
