import {
  EventQuestChainId,
  EventQuestChains,
} from '@victgame/akiverse-backend/dist/quests/event_quest_chains'
import { convertQuestDate, getNowDate } from '../utils/DateUtil'

// イベントクエストの日付表示
const dateToString = (d?: Date) => (d ? convertQuestDate(d) : '')
export const eventDateToString = (startAt?: Date, endAt?: Date) =>
  startAt && endAt ? `${dateToString(startAt)} ~ ${dateToString(endAt)} UTC+9` : ''

// 期間内のイベントクエストを特定する
export const getWithinEventQuestKeys = () =>
  Object.keys(EventQuestChains).filter((key) => {
    const master = EventQuestChains?.[key as EventQuestChainId]
    if (master) {
      const now = getNowDate().toDate()
      const { startAt, endAt } = master
      if (startAt && endAt) {
        return now >= startAt && endAt >= now
      } else if (startAt) {
        return now >= startAt
      } else if (endAt) {
        return endAt >= now
      }
    }
    return true
  })
