import { useCallback, useEffect, useState } from 'react'
import { Props } from './Layout'
import {
  FrontEndType,
  IconType,
  NftState,
  QuestChain,
  useCurrentUserQuestChainsQuery,
  useGetHomeQuery,
  useListBannersQuery,
  useStartQuestChainMutation,
} from './query.generated'
import { useLoginUser } from '../../../stores/LoginUser'
import { useRouter } from 'next/router'
import { PagePaths } from '@/src/utils/PagePaths'
import { Props as QuestProps, QuestState, QuestTabType } from '../../parts/commons/QuestCell'
import {
  OnwardQuestChainId,
  OnwardQuestChains,
} from '@victgame/akiverse-backend/dist/quests/onward_quest_chains'
import {
  EventQuestChainId,
  EventQuestChains,
} from '@victgame/akiverse-backend/dist/quests/event_quest_chains'
import { VipQuestChainId, VipQuestChains } from '@victgame/akiverse-backend/dist/quests/vip_quest_chains'
import { getWithinEventQuestKeys } from '@/src/services/QuestService'
import { SortOrder } from '@/src/graphql/generated/types'
import { SearchDate } from '@/src/utils/DateUtil'
import { useGameInfo } from '@/src/stores/Games/useGames'
import { S3URLService } from '@/src/services/S3URLService'

export const useProps = (): Props => {
  const [visible, setVisible] = useState(false)
  const [questList, setQuestList] = useState<Array<QuestProps>>([])
  const [allGameCenters, setAllGameCenters] = useState<Props['gameCenters']>([])
  const [allArcadeMachines, setAllArcadeMachines] = useState<Props['arcadeMachines']>([])

  const [now] = useState(SearchDate())

  const router = useRouter()

  const loginUser = useLoginUser().loginUser
  const userId = loginUser?.id ?? ''
  const iconType = loginUser?.iconType
  const { games } = useGameInfo()
  const homeQuery = useGetHomeQuery({
    variables: {
      gameCenterWhere: { userId: { equals: userId } },
      arcadeMachinesWhere: {
        userId: { equals: userId },
        game: { in: games.map((e) => e.id) },
        destroyedAt: null,
      },
      gameCenterOrderBy: [{ createdAt: SortOrder.Desc }],
      arcadeMachineOrderBy: [{ createdAt: SortOrder.Desc }],
    },
  })

  const { data: currentQuest, refetch } = useCurrentUserQuestChainsQuery()

  const [banners, setBanners] = useState<Props['banners']>([])
  const { data: bannerData } = useListBannersQuery({
    variables: {
      where: {
        frontEndType: { equals: FrontEndType.Gp },
        display: { equals: true },
        AND: [
          { OR: [{ endAt: null }, { endAt: { gte: now } }] },
          { OR: [{ startAt: null }, { startAt: { lte: now } }] },
        ],
      },
      orderBy: { id: SortOrder.Desc },
    },
  })

  const [startQuestChain] = useStartQuestChainMutation()

  // クエストチェーンに紐づくクエストマスタを取得する
  const getQuestMasterData = (userQuest: QuestChain) => {
    const onward = OnwardQuestChains?.[userQuest?.questChainMasterId as OnwardQuestChainId] ?? null
    if (onward) {
      const displayIdList = Object.keys(OnwardQuestChains).map((_, i) => ({ displayId: `Quest ${i + 1}` }))
      return {
        type: QuestTabType.ONWARD,
        info: onward,
        displayId: displayIdList[Object.keys(OnwardQuestChains).indexOf(onward.id)]?.displayId ?? null,
      }
    }

    const event = EventQuestChains?.[userQuest?.questChainMasterId as EventQuestChainId] ?? null
    if (event) {
      return { type: QuestTabType.EVENT, info: event, displayId: null }
    }

    const vip = VipQuestChains?.[userQuest?.questChainMasterId as VipQuestChainId] ?? null
    if (vip) {
      return { type: QuestTabType.VIP, info: vip, displayId: null }
    }

    return null
  }

  // イベントクエストを開始する
  const startEvent = useCallback(
    (chains: Array<QuestChain>, eventKeys: Array<string>) => {
      for (const eventId of eventKeys) {
        const master = EventQuestChains?.[eventId as EventQuestChainId]
        const current = chains.find((e) => e.questChainMasterId === eventId)
        if (!current && master) {
          startQuestChain({ variables: { input: { questMasterId: master.id } } })
            .then(async (res) => {
              if (res.errors) {
                alert(res?.errors?.[0]?.message ? res.errors[0].message : 'Error')
              } else {
                await refetch()
              }
            })
            .catch(async () => {
              await refetch()
            })
        }
      }
    },
    [refetch, startQuestChain],
  )

  // VIPクエストを開始する
  const startVip = useCallback(
    (chains: Array<QuestChain>, vipKeys: Array<string>) => {
      for (const vipId of vipKeys) {
        const master = VipQuestChains?.[vipId as VipQuestChainId]
        const current = chains.find((e) => e.questChainMasterId === vipId)
        if (!current && master) {
          startQuestChain({ variables: { input: { questMasterId: master.id } } })
            .then(async (res) => {
              if (res.errors) {
                alert(res?.errors?.[0]?.message ? res.errors[0].message : 'Error')
              } else {
                await refetch()
              }
            })
            .catch(async () => {
              await refetch()
            })
        }
      }
    },
    [refetch, startQuestChain],
  )

  // 画面表示データを作成する
  const createQuestData = useCallback(
    (userQuest: QuestChain, eventKeys: Array<string>): QuestProps | null => {
      const master = getQuestMasterData(userQuest)
      const nowQuest = userQuest.quests.find((e) => !e.completedAt) ?? null
      const nowMasterQuest = nowQuest
        ? master?.info.quests.find((e) => e.id === nowQuest.questMasterId)
        : master?.info.quests.find(
            (e) => e.seq === userQuest.quests.filter((e) => e.completedAt).length + 1,
          ) ?? master?.info.quests.slice(-1)[0]

      if (!master || !nowMasterQuest) return null

      // 期間外のEVENT表示しない
      if (master.type === QuestTabType.EVENT && !eventKeys.includes(master.info.id)) {
        return null
      }

      const allProgressGoal = master.info.quests.length
      const allProgress = userQuest.quests.filter((e) => e.completedAt).length
      const state = allProgressGoal === allProgress ? 'Completed' : 'Default'

      const titleMap = {
        [QuestTabType.ONWARD]: master.displayId,
        [QuestTabType.EVENT]: master.info.title,
        [QuestTabType.PREMIUM]: master.info.title,
        [QuestTabType.VIP]: master.info.title,
      }

      return {
        state,
        type: master.type,
        questInfo: {
          title: titleMap[master.type] || '',
          allProgressGoal,
          allProgress,
          text: nowMasterQuest.title,
          progressGoal: nowMasterQuest.progressGoal,
          progress: nowQuest?.progress ?? (state === 'Completed' ? nowMasterQuest.progressGoal : 0),
        },
        onClick: () =>
          router.push(
            PagePaths.quest({
              tabType: {
                [QuestTabType.ONWARD]: 'ONWARD',
                [QuestTabType.EVENT]: 'EVENT',
                [QuestTabType.PREMIUM]: 'PREMIUM',
                [QuestTabType.VIP]: 'VIP',
              }[master.type as QuestTabType] as 'ONWARD' | 'EVENT' | 'PREMIUM' | 'VIP',
              questChainMasterId: userQuest.questChainMasterId,
            }),
          ),
      }
    },
    [router],
  )

  useEffect(() => {
    if (!currentQuest) return

    const res = currentQuest?.currentUserQuestChains ?? []
    const onwardKeys = Object.keys(OnwardQuestChains)
    const eventKeys = getWithinEventQuestKeys()
    const vipKeys = Object.keys(VipQuestChains)

    // クエスト情報が設定されていない場合
    if (onwardKeys.length === 0 && eventKeys.length === 0 && vipKeys.length === 0) {
      setQuestList([{ state: 'NO_QUEST', type: QuestTabType.ONWARD }])
      return
    }

    // 期間内のイベントクエストを開始
    startEvent(res as Array<QuestChain>, eventKeys)
    iconType === IconType.Nft && startVip(res as Array<QuestChain>, vipKeys)

    // クエスト情報設定
    const onward: Array<QuestProps> = []
    const event: Array<QuestProps> = []
    const vip: Array<QuestProps> = []
    for (const userQuest of res.filter((userQuest) => !userQuest.completed)) {
      const data = createQuestData(userQuest as QuestChain, eventKeys)
      if (!data) continue
      if (data.type === QuestTabType.ONWARD) {
        onward.push(data)
      } else if (data.type === QuestTabType.EVENT) {
        event.push(data)
      } else if (data.type === QuestTabType.VIP) {
        iconType === IconType.Nft && vip.push(data)
      }
    }

    // クエストにJOINしていない場合
    // 次のクエストがあればNO_JOIN、なければNO_QUEST
    if (onward.length === 0) {
      const onwardChainResult = onwardKeys.filter(
        (key) => res.findIndex((e) => e.questChainMasterId === key) !== -1,
      )
      onward.push(
        onwardChainResult.length !== onwardKeys.length
          ? {
              state: 'NO_JOIN',
              type: QuestTabType.ONWARD,
              onClick: () => router.push(PagePaths.quest()),
            }
          : {
              state: 'NO_QUEST',
              type: QuestTabType.ONWARD,
            },
      )
    }

    // Completed > NO_JOIN > Default > NO_QUEST & Event > Onward > VIP 順にする
    const stateOrder: Record<QuestState, number> = {
      Completed: 1,
      NO_JOIN: 2,
      Default: 3,
      NO_QUEST: 4,
    }
    const quests = [...event, ...onward, ...vip].sort((a, b) => stateOrder[a.state] - stateOrder[b.state])
    setQuestList(quests)
  }, [currentQuest, createQuestData, startEvent, startVip, router])

  useEffect(() => {
    setAllGameCenters(
      homeQuery.data?.gameCenters.map((gc) => ({
        id: gc.id,
        isDeposit: gc.state === NftState.InAkiverse,
        imageUrl: gc.metadata.transparentImageUrl ?? '',
        name: gc.name,
      })) ?? [],
    )

    setAllArcadeMachines(
      homeQuery.data?.arcadeMachines.map((am) => ({
        id: am.id,
        state:
          am.state !== NftState.InAkiverse
            ? 'NO_DEPOSIT'
            : am.feverSparkRemain === 0
            ? 'END'
            : !!am.feverSparkRemain && am.feverSparkRemain > 0
            ? 'MEGA_SPARKING'
            : 'DEFAULT',
        imageUrl: am.metadata.withoutAccImageUrl ?? '',
        name: am.metadata.name,
        energy: am.energy,
        maxEnergy: am.maxEnergy,
        extractedEnergy: am.extractedEnergy,
        feverSparkRemain: am.feverSparkRemain ?? null,
      })) ?? [],
    )
  }, [homeQuery])

  useEffect(() => {
    setBanners(
      bannerData?.listBanners.map((e) => ({
        id: e.id,
        imageUrl: e.mainImageUrl,
        externalLink: e.externalLink ?? '',
      })) || [],
    )
  }, [bannerData])

  return {
    banners,
    games: games
      // onlyTournamentは除く
      .filter((g) => !g.onlyTournament)
      .reverse()
      .map((g) => ({
        id: g.id,
        imageUrl: S3URLService.getGameThumbnailImageURL(g.id),
        name: g.name,
        category: g.category,
        rule: g.rule,
      })),
    // depositされているものを先頭にする
    gameCenters: [
      ...allGameCenters.filter((e) => e.isDeposit),
      ...allGameCenters.filter((e) => !e.isDeposit),
    ],
    // depositされているものを先頭にする
    arcadeMachines: [
      ...allArcadeMachines.filter((e) => e.state === 'MEGA_SPARKING'),
      ...allArcadeMachines.filter((e) => e.state === 'DEFAULT'),
      ...allArcadeMachines.filter((e) => e.state === 'END'),
      ...allArcadeMachines.filter((e) => e.state === 'NO_DEPOSIT'),
    ],
    visible,
    setVisible,
    questList,
    onClickQuestList: () => router.push(PagePaths.quest()),
  }
}
