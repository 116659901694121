import React from 'react'
import { RequiredAuthProvider } from '../../providers/RequiredAuthProvider'
import { Layout } from './Layout'
import { useProps } from './useProps'

const Page = () => {
  return <Layout {...useProps()} />
}

export const TopPage = () => {
  return (
    <RequiredAuthProvider>
      <Page />
    </RequiredAuthProvider>
  )
}
