import { Absolute } from '../bases/Absolute'
import { HStack } from '../bases/HStack'
import { Text } from '../bases/Text'
import { FEVER_SPARK_REMAIN_DENOMINATOR } from './MegaSparkingLabel'

export const MegaSparkingFullLabel = (props: { feverSparkRemain: number; className?: string }) => {
  const isMegaSparking = props.feverSparkRemain > 0
  return (
    <Absolute className={`w-full ${props.className}`}>
      <HStack className="w-full items-center justify-start gap-x-1">
        <Text
          className={`rounded px-2 text-sm font-bold ${isMegaSparking ? 'text-white' : 'text-white/60'}`}
          style={{
            textShadow: isMegaSparking ? '0px 0px 10px #FFF' : '',
            background: isMegaSparking
              ? 'linear-gradient(92deg, rgba(241, 167, 56, 0.80) 0.11%, rgba(237, 241, 56, 0.80) 100%)'
              : '#212226',
          }}
        >
          MEGA SPARKING
        </Text>
        <HStack className="items-end justify-center rounded bg-black/40 px-1 backdrop-blur-[2.8px]">
          <Text
            className={`text-base font-bold ${isMegaSparking ? 'text-akiverse-yellow' : 'text-[#DC325B]'}`}
          >
            {props.feverSparkRemain}
          </Text>
          <Text className="mb-0.5 text-xs font-bold text-white">/{FEVER_SPARK_REMAIN_DENOMINATOR}</Text>
        </HStack>
      </HStack>
    </Absolute>
  )
}
