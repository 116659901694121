import Image from 'next/image'
import Link from 'next/link'
import { PagePaths } from '../../../utils/PagePaths'
import { Absolute } from '../../parts/bases/Absolute'
import { Center } from '../../parts/bases/Center'
import { HStack } from '../../parts/bases/HStack'
import { Text } from '../../parts/bases/Text'
import { VStack } from '../../parts/bases/VStack'
import { EnergyGauge } from '../../parts/commons/EnergyGauge'
import { GlobalNavigationLayout } from '../../parts/navigations/GlobalNavigationLayout'
import { Fixed } from '../../parts/bases/Fixed'
import { PlayAndCraftCampaignModalButton } from '../../parts/modals/PlayAndCraftCampaignModal'
import { QuestCell, Props as QuestProps } from '../../parts/commons/QuestCell'
import IconArrowRight from '@/public/images/iconArrowRight.png'
import { Clickable } from '../../parts/bases/Clickable'
import { PropsWithChildren } from 'react'
import { MegaSparkingFullLabel } from '../../parts/commons/MegaSparkingFullLabel'
import megasparkingAM from '@/public/images/megasparking_03.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

type AMState = 'DEFAULT' | 'MEGA_SPARKING' | 'END' | 'NO_DEPOSIT'

export type Props = {
  games: Array<{ id: string; imageUrl: string; name: string; category: string; rule: string }>
  gameCenters: Array<{ id: string; isDeposit: boolean; imageUrl: string; name: string }>
  arcadeMachines: Array<{
    id: string
    state: AMState
    imageUrl: string
    name: string
    energy: number
    maxEnergy: number
    extractedEnergy: number
    feverSparkRemain: number | null
  }>

  visible: boolean
  setVisible: (visible: boolean) => void

  questList: Array<QuestProps>
  onClickQuestList: () => void

  banners: Array<{ id: number; imageUrl: string; externalLink?: string }>
}

export const Layout = (props: Props) => {
  return (
    <>
      <GlobalNavigationLayout>
        <VStack className="mt-2">
          {props.banners.length > 0 && (
            <div className={`w-full px-4 ${props.banners.length === 1 ? 'mb-4' : ''}`}>
              <Swiper
                initialSlide={0}
                modules={[Autoplay, Pagination]}
                loop={props.banners.length >= 2}
                autoplay={{ delay: 5000 }}
                pagination={{ clickable: true }}
              >
                {props.banners.map((e) => (
                  <SwiperSlide key={e.id}>
                    {e.externalLink ? (
                      <Clickable key={e.id} onClick={() => window.open(e.externalLink)} className="h-full">
                        <img
                          src={e.imageUrl}
                          className="h-full w-full rounded-md border border-[#52369F] object-contain"
                          alt="banner"
                        />
                      </Clickable>
                    ) : (
                      <Center key={e.id} className="h-full">
                        <img
                          src={e.imageUrl}
                          className="h-full w-full rounded-md border border-[#52369F] object-contain"
                          alt="banner"
                        />
                      </Center>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          <HStack className="mb-2 items-center justify-between pl-4 pr-1.5">
            <Text className="text-2xl font-bold text-white drop-shadow-md">Ongoing Quests</Text>
            <Clickable onClick={props.onClickQuestList} className="items-center justify-between p-2">
              <Text className="text-base font-bold text-neon-blue">more</Text>
              <Center className="h-6 w-6">
                <Image src={IconArrowRight} alt="arrowRight" />
              </Center>
            </Clickable>
          </HStack>
          <HStack className="w-full gap-x-3 overflow-x-scroll p-3">
            {props.questList.map((quest, i) => (
              <QuestCell key={`quest_${i}`} {...quest} />
            ))}
          </HStack>
          <Text className="px-4 text-2xl font-bold text-white drop-shadow-md">Games</Text>
          <VStack className="mt-8 w-full px-4">
            {props.games.map((item) => (
              <Link key={item.id} href={PagePaths.arcadeMachineSearch(item.id)}>
                <a>
                  <VStack className="mb-4">
                    <VStack className=" aspect-square w-full">
                      <Image src={item.imageUrl} sizes="100%" layout="fill" alt={item.name} />
                      <Absolute
                        className={`right-2 top-2 items-center justify-center rounded-full bg-[#BD00FF] p-2 px-3 py-0.5`}
                      >
                        <Text className="text-xs font-bold text-white">{item.rule}</Text>
                      </Absolute>
                    </VStack>
                    <span className="mt-1 text-lg font-bold text-white">{item.name}</span>
                    <span className="text-sm font-bold text-neutral-400">{item.category}</span>
                  </VStack>
                </a>
              </Link>
            ))}
          </VStack>
          <Text className="mt-6 px-4 text-2xl font-bold text-white">My GameCenter</Text>
          {props.gameCenters.length > 0 ? (
            <HStack className="mt-4 w-full overflow-scroll">
              <HStack className="items-top px-2">
                {props.gameCenters.map((gc) => (
                  <LinkComponent
                    key={gc.id}
                    disabled={!gc.isDeposit}
                    href={PagePaths.gameCenter({ gameCenterId: gc.id })}
                  >
                    <VStack className="mx-4 w-40 pb-4">
                      <VStack className="aspect-square h-40 w-full">
                        <Image
                          src={gc.imageUrl}
                          sizes="100%"
                          layout="fill"
                          objectFit="contain"
                          alt={gc.name}
                          className={`${gc.isDeposit ? '' : 'opacity-50'}`}
                        />
                      </VStack>
                      <div className="relative mt-2">
                        {!gc.isDeposit && (
                          <Absolute className="-top-10">
                            <span className={`text-sm font-bold text-akiverse-yellow`}>
                              Deposit at World Manager from your PC
                            </span>
                          </Absolute>
                        )}
                        <span
                          className={`mt-2 text-lg font-bold text-white ${gc.isDeposit ? '' : 'opacity-50'}`}
                        >
                          {gc.name}
                        </span>
                      </div>
                    </VStack>
                  </LinkComponent>
                ))}
              </HStack>
            </HStack>
          ) : (
            <NoData text="You do not own any GameCenter." />
          )}
          <Text className="mt-6 px-4 text-2xl font-bold text-white">My ArcadeMachine</Text>
          {props.arcadeMachines.length > 0 ? (
            <HStack className="mt-4 w-full overflow-scroll pb-4">
              <HStack className="items-center gap-x-2 px-6">
                {props.arcadeMachines.map((am) => {
                  const showMegaSparking =
                    am.feverSparkRemain !== null && ['MEGA_SPARKING', 'END'].includes(am.state)
                  return (
                    <LinkComponent
                      key={am.id}
                      disabled={!['DEFAULT', 'MEGA_SPARKING'].includes(am.state)}
                      href={PagePaths.arcademachineDetail({ arcadeMachineId: am.id })}
                    >
                      <VStack className={`w-full ${showMegaSparking ? 'mr-8' : ''}`}>
                        <VStack className="relative aspect-square h-40 w-full">
                          <Image
                            src={am.imageUrl}
                            sizes="100%"
                            objectFit="contain"
                            layout="fill"
                            alt={am.name}
                            className={`${'END' === am.state ? 'grayscale' : ''} ${
                              'NO_DEPOSIT' === am.state ? 'opacity-50' : ''
                            }`}
                          />
                          <Absolute className={`bottom-6 ${showMegaSparking ? 'left-11' : 'left-7'}`}>
                            <EnergyGauge
                              height={90}
                              maxEnergy={am.maxEnergy}
                              energy={am.energy}
                              extractedEnergy={am.extractedEnergy}
                              feverSparkRemain={am.feverSparkRemain}
                              className={`${'NO_DEPOSIT' === am.state ? 'opacity-50' : ''}`}
                            />
                          </Absolute>
                          {!!am.feverSparkRemain && am.feverSparkRemain > 0 && (
                            <Absolute className="h-full w-full">
                              <Image
                                src={megasparkingAM}
                                sizes="100%"
                                objectFit="contain"
                                layout="fill"
                                className={`h-full w-full ${'NO_DEPOSIT' === am.state ? 'opacity-50' : ''}`}
                                alt=""
                              />
                            </Absolute>
                          )}
                          {am.feverSparkRemain !== null && ['MEGA_SPARKING', 'END'].includes(am.state) && (
                            <MegaSparkingFullLabel
                              feverSparkRemain={am.feverSparkRemain}
                              className="bottom-0"
                            />
                          )}
                        </VStack>
                        <div className="relative mt-2">
                          {['NO_DEPOSIT'].includes(am.state) && (
                            <Absolute className="-top-10">
                              <span className={`text-sm font-bold text-akiverse-yellow`}>
                                Deposit at World Manager from your PC
                              </span>
                            </Absolute>
                          )}
                          <span
                            className={`text-lg font-bold text-white ${
                              'NO_DEPOSIT' === am.state ? 'opacity-50' : ''
                            }`}
                          >
                            {am.name}
                          </span>
                        </div>
                        <span
                          className={`text-sm font-bold text-neutral-400 ${
                            'NO_DEPOSIT' === am.state ? 'opacity-50' : ''
                          }`}
                        >
                          {am.id}
                        </span>
                      </VStack>
                    </LinkComponent>
                  )
                })}
              </HStack>
            </HStack>
          ) : (
            <NoData text="You do not own any ArcadeMachine." />
          )}
        </VStack>
      </GlobalNavigationLayout>
      <Fixed
        className={`bottom-20 right-2 mb-[env(safe-area-inset-bottom)] ${props.visible ? 'z-[100]' : ''}`}
      >
        <PlayAndCraftCampaignModalButton visible={props.visible} setVisible={props.setVisible} />
      </Fixed>
    </>
  )
}

const LinkComponent = (props: PropsWithChildren<{ disabled: boolean; href: string }>) => {
  return !props.disabled ? (
    <Link href={props.href}>
      <a>{props.children}</a>
    </Link>
  ) : (
    <div>{props.children}</div>
  )
}

const NoData = (props: { text: string }) => (
  <Center className="w-full flex-col py-8">
    <Image src={'/images/nodata.png'} width={120} height={120} alt={'No Data'} />
    <span className="mt-2 text-sm text-neutral-400">{props.text}</span>
  </Center>
)
