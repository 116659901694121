import React, { useEffect } from 'react'
import { Clickable } from '../../bases/Clickable'
import { Fixed } from '../../bases/Fixed'
import { VStack } from '../../bases/VStack'
import { Absolute } from '../../bases/Absolute'
import { Center } from '../../bases/Center'
import { HStack } from '../../bases/HStack'
import { Text } from '../../bases/Text'

type TabType = 'GamePlay' | 'Craft'

type CampaignData = {
  id: string
  label: string
  percentage: number
  image: string
  completeImage: string
  count: number
  isComplete: boolean
}

export type Props = {
  withinCampaign: boolean
  visible: boolean
  selectTab: TabType
  onClickOpen: () => void
  onClickClose: () => void
  onClickTab: (tab: TabType) => void
  onClickCampaginDetail: () => void
  playCountData: Array<CampaignData>
  isPlayCountCompleted: boolean
  craftData: Array<CampaignData>
  isCraftCompleted: boolean
  currentRecord: string | null
}

export const Layout = (props: Props) => {
  const isGamePlayTab = props.selectTab === 'GamePlay'

  const scrollRef = React.createRef<HTMLDivElement>()

  const scrollCell = React.useCallback(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [scrollRef])

  useEffect(() => {
    if (props.currentRecord && scrollRef) {
      setTimeout(() => {
        scrollCell()
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentRecord, scrollRef])

  return (
    <>
      {props.withinCampaign && (
        <Clickable onClick={props.onClickOpen}>
          <img src="/images/playAndCraftMission/banner.png" className="w-32" alt="" />
        </Clickable>
      )}
      {props.withinCampaign && props.visible ? (
        <Fixed className="top-0 left-0 z-[100] h-full w-full overflow-scroll overscroll-contain bg-black bg-opacity-70 pt-[env(safe-area-inset-top)] pb-[env(safe-area-inset-bottom)]">
          <style global jsx>{`
            body,
            html {
              overflow: hidden;
            }
          `}</style>
          <VStack>
            <div className="relative flex aspect-[1125/1986] w-full flex-col">
              <img className="" src="/images/playAndCraftMission/campaign_bg.png" alt="" />
              <Absolute className="absolute top-0 left-0 z-10 w-full pt-[36%] pb-[0%] pl-[8%] pr-[8%]">
                <div
                  className="relative aspect-[969/138] w-full bg-cover bg-center"
                  style={{
                    backgroundImage: `url('/images/playAndCraftMission/${
                      isGamePlayTab ? 'tab01_gameplay' : 'tab02_craft'
                    }.png')`,
                  }}
                >
                  <Absolute className="left-0 h-full w-[50%]">
                    <div className="h-full w-full" onClick={() => props.onClickTab('GamePlay')}></div>
                  </Absolute>
                  <Absolute className="right-0 h-full w-[50%]">
                    <div className="h-full w-full" onClick={() => props.onClickTab('Craft')}></div>
                  </Absolute>
                </div>
              </Absolute>
              <Absolute className="absolute top-0 left-0 h-full w-full pt-[50%] pb-[30%] pl-[8%] pr-[7%]">
                {isGamePlayTab ? (
                  props.isPlayCountCompleted ? (
                    <img className="w-full" src="/images/playAndCraftMission/completed_gameplay.png" alt="" />
                  ) : (
                    <VStack
                      key="play_list"
                      className="yellow_scroll_bar h-full w-full overflow-hidden overflow-x-hidden overflow-y-scroll pr-1"
                    >
                      {props.playCountData.map((data) => (
                        <div
                          key={data.id}
                          className={`relative flex h-full w-full flex-row items-center justify-center`}
                          ref={props.currentRecord === data.id ? scrollRef : null}
                        >
                          <CellContent data={data} />
                        </div>
                      ))}
                    </VStack>
                  )
                ) : props.isCraftCompleted ? (
                  <img className="w-full" src="/images/playAndCraftMission/completed_craft.png" alt="" />
                ) : (
                  <VStack
                    key="craft_list"
                    className="yellow_scroll_bar h-full w-full overflow-hidden overflow-x-hidden overflow-y-scroll pr-1"
                  >
                    {props.craftData.map((data) => (
                      <div
                        key={data.id}
                        className={`relative flex h-full w-full flex-row items-center justify-center`}
                        ref={props.currentRecord === data.id ? scrollRef : null}
                      >
                        <CellContent data={data} />
                      </div>
                    ))}
                  </VStack>
                )}
              </Absolute>
              <Absolute className="left-[5%] right-[5%] bottom-[8%]">
                <Center className="w-full">
                  {isGamePlayTab ? (
                    <img src="/images/playAndCraftMission/read01_gameplay.png" alt="" />
                  ) : (
                    <img src="/images/playAndCraftMission/read02_craft.png" alt="" />
                  )}
                </Center>
              </Absolute>
              <Absolute className="left-[20%] right-[20%] bottom-[3.5%]">
                <Clickable onClick={props.onClickCampaginDetail}>
                  <Center className="w-full">
                    <img src="/images/playAndCraftMission/btn_campaign.png" alt="" />
                  </Center>
                </Clickable>
              </Absolute>
              <Absolute className="right-10 bottom-8">
                <Clickable onClick={props.onClickClose}>
                  <Center className="w-8">
                    <img src="/images/playAndCraftMission/btn_cancel.png" alt="" />
                  </Center>
                </Clickable>
              </Absolute>
            </div>
          </VStack>
        </Fixed>
      ) : null}
    </>
  )
}

const Gauge = (props: { percentage: number }) => {
  return (
    <HStack className="relative h-full w-full bg-[url(/images/playAndCraftMission/gauge_base.png)] p-[3px]">
      <VStack
        className="h-full bg-[url(/images/playAndCraftMission/gauge_bar.png)] bg-contain bg-repeat-x"
        style={{ width: `${props.percentage}%` }}
      />
    </HStack>
  )
}

const CellContent = (props: { data: CampaignData }) => {
  const { data } = props
  return (
    <>
      <div className="relative aspect-[940/198] h-full w-full">
        <img src={data.image} alt="" />
      </div>
      <Absolute className="left-[2%] bottom-[25%] h-[14%] w-[65%] items-center">
        <Gauge percentage={data.percentage} />
        <Text className="ml-2 text-sm font-bold text-[#FFD966]">{data.label}</Text>
      </Absolute>
      <Absolute className={`top-0 left-0 h-full w-full ${data.isComplete ? 'z-10' : '-z-10'}`}>
        <div className="relative aspect-[940/198] h-full w-full">
          <img src={data.completeImage} alt="" />
        </div>
      </Absolute>
    </>
  )
}
