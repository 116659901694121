import React from 'react'
import { VStack } from '../bases/VStack'
import { HStack } from '../bases/HStack'
import { Text } from '../bases/Text'

export const QuestGauge = ({
  progress,
  progressGoal,
  className,
}: {
  progress: number
  progressGoal: number
  // widthはclassNameで指定する
  className?: string
}) => {
  return (
    <>
      <HStack className={`relative h-3 items-center bg-[url(/images/gauge_base.png)] p-[3px] ${className}`}>
        <VStack
          className="h-2 bg-[url(/images/gauge_bar.png)] bg-contain bg-repeat-x"
          style={{ width: `${100 * Math.min(1, progress / progressGoal)}%` }}
        />
      </HStack>
      <Text className="ml-2 text-sm font-bold text-akiverse-yellow">
        {progress}/{progressGoal}
      </Text>
    </>
  )
}
