import { useRouter } from 'next/router'
import React, { PropsWithChildren, useEffect } from 'react'
import { useLoginUser } from '../../stores/LoginUser'
import { PagePaths } from '../../utils/PagePaths'
import { Center } from '../parts/bases/Center'
import { ActivityIndicaotr } from '../parts/bases/Indicator'
import { AgreementProvider } from './AgreementProvider'
import { LocalStorageService } from '@/src/services/LocalStorageService'
import { useFirebaseUser } from '@/src/stores/FirebaseUser'

type Props = PropsWithChildren<{}>

export const RequiredAuthProvider = (props: Props) => {
  const { isLoading: loginUserLoading, loginUser, reload } = useLoginUser()
  const { isLoading: firebaseLoading, firebaseUser } = useFirebaseUser()
  useEffect(() => {
    reload()
  }, [reload])

  return (
    <>
      {loginUser && firebaseUser && !LocalStorageService.loadAccountLock() ? (
        <AgreementProvider>{props.children}</AgreementProvider>
      ) : loginUserLoading || firebaseLoading ? (
        <Center className="h-screen w-screen">
          <ActivityIndicaotr />
        </Center>
      ) : (
        <Redirecter
          path={LocalStorageService.loadAccountLock() ? PagePaths.accountLocked() : PagePaths.login()}
        />
      )}
    </>
  )
}

const Redirecter = (props: { path: string }) => {
  const router = useRouter()
  useEffect(() => {
    router.push(props.path)
  }, [router, props.path])
  return null
}
