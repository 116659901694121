import { useEffect, useState } from 'react'
import { Props } from './Layout'
import { usePlaysForPlayCampaginQuery, useCraftsForCraftCampaginQuery } from './query.generated'
import { useLoginUser } from '@/src/stores/LoginUser'
import dayjs from 'dayjs'
import { LocalStorageService } from '@/src/services/LocalStorageService'
import { TERM_TIME_ZONE } from '@/src/constants'
import { SearchDate } from '@/src/utils/DateUtil'

const campaignConfig = {
  playCountDatas: [
    { playCount: 5, imageNum: '01' },
    { playCount: 10, imageNum: '02' },
    { playCount: 15, imageNum: '03' },
    { playCount: 20, imageNum: '04' },
    { playCount: 25, imageNum: '05' },
    { playCount: 30, imageNum: '06' },
    { playCount: 35, imageNum: '07' },
    { playCount: 40, imageNum: '08' },
    { playCount: 45, imageNum: '09' },
    { playCount: 50, imageNum: '10' },
    { playCount: 60, imageNum: '11' },
    { playCount: 70, imageNum: '12' },
    { playCount: 80, imageNum: '13' },
    { playCount: 90, imageNum: '14' },
    { playCount: 100, imageNum: '15' },
    { playCount: 150, imageNum: '150' },
    { playCount: 200, imageNum: '200' },
    { playCount: 250, imageNum: '250' },
    { playCount: 300, imageNum: '300' },
    { playCount: 400, imageNum: '400' },
    { playCount: 500, imageNum: '500' },
    { playCount: 5000, imageNum: '5000' },
  ],
  maxPlayCount: 5000,
  craftDatas: [
    { craftCount: 1, imageNum: '01', onImageNum: '01-09_11_19' },
    { craftCount: 2, imageNum: '02', onImageNum: '01-09_11_19' },
    { craftCount: 3, imageNum: '03', onImageNum: '01-09_11_19' },
    { craftCount: 4, imageNum: '04', onImageNum: '01-09_11_19' },
    { craftCount: 5, imageNum: '05', onImageNum: '01-09_11_19' },
    { craftCount: 6, imageNum: '06', onImageNum: '01-09_11_19' },
    { craftCount: 7, imageNum: '07', onImageNum: '01-09_11_19' },
    { craftCount: 8, imageNum: '08', onImageNum: '01-09_11_19' },
    { craftCount: 9, imageNum: '09', onImageNum: '01-09_11_19' },
    { craftCount: 10, imageNum: '10', onImageNum: '10' },
    { craftCount: 11, imageNum: '11', onImageNum: '01-09_11_19' },
    { craftCount: 12, imageNum: '12', onImageNum: '01-09_11_19' },
    { craftCount: 13, imageNum: '13', onImageNum: '01-09_11_19' },
    { craftCount: 14, imageNum: '14', onImageNum: '01-09_11_19' },
    { craftCount: 15, imageNum: '15', onImageNum: '01-09_11_19' },
    { craftCount: 16, imageNum: '16', onImageNum: '01-09_11_19' },
    { craftCount: 17, imageNum: '17', onImageNum: '01-09_11_19' },
    { craftCount: 18, imageNum: '18', onImageNum: '01-09_11_19' },
    { craftCount: 19, imageNum: '19', onImageNum: '01-09_11_19' },
    { craftCount: 20, imageNum: '20', onImageNum: '20' },
  ],
  maxCraftCount: 20,
  detailUrl: 'https://medium.com/akiverse/akiverse-indigg-collaboration-campaign-683f2a81a9f0',
  startDate: dayjs('2023-06-13 10:00', 'yyyy-MM-dd HH:mm', TERM_TIME_ZONE).toDate(),
  endDate: dayjs('2023-08-21 03:00', 'yyyy-MM-dd HH:mm', TERM_TIME_ZONE).toDate(),
}

export type Params = {
  visible: boolean
  setVisible: (visible: boolean) => void
}

export const useProps = (props: Params): Props => {
  const [now] = useState(SearchDate())
  const [playCountData, setPlayCountData] = useState<Props['playCountData']>([])
  const [craftData, setCraftData] = useState<Props['craftData']>([])
  const [selectTab, setSelectTab] = useState<Props['selectTab']>('GamePlay')
  const [currentRecord, setCurrentRecord] = useState<Props['currentRecord']>(null)

  const userId = useLoginUser().loginUser?.id ?? ''
  const { data: playBaseData } = usePlaysForPlayCampaginQuery({
    variables: {
      where: {
        playSession: { is: { playerId: { equals: userId } } },
        createdAt: { gte: campaignConfig.startDate, lte: campaignConfig.endDate },
      },
    },
  })
  const { data: craftBaseData } = useCraftsForCraftCampaginQuery({
    variables: {
      where: {
        userId: { equals: userId },
        createdAt: { gte: campaignConfig.startDate, lte: campaignConfig.endDate },
      },
    },
  })
  const playCount = playBaseData?.plays.length ?? 0
  const craftCount = craftBaseData?.crafts.length ?? 0

  useEffect(() => {
    if (props.visible) {
      // 初回表示時のアニメーション設定
      if (selectTab === 'GamePlay') {
        const latestPlayCount = LocalStorageService.loadPlayCampaignDisplay()
        if (playCount === latestPlayCount) {
          setCurrentRecord(null)
          const data = playCountData.map((e) => ({ ...e, isComplete: e.percentage === 100 }))
          setPlayCountData([
            ...data.filter((e) => e.count > latestPlayCount),
            ...data.filter((e) => e.count <= latestPlayCount),
          ])
        } else {
          for (let i = 0; i < playCountData.length; i++) {
            const data = playCountData[i]!
            if (data.count <= playCount) {
              setCurrentRecord(playCountData?.[i + 1]?.id ?? null)
            } else {
              // 判定が終わったので抜ける
              break
            }
          }
          LocalStorageService.savePlayCampaignDisplay(playCount)
          setTimeout(() => {
            setPlayCountData(playCountData.map((e) => ({ ...e, isComplete: e.percentage === 100 })))
          }, 500)
        }
      } else {
        const latestCraftCount = LocalStorageService.loadCraftCampaignDisplay()
        if (craftCount === latestCraftCount) {
          setCurrentRecord(null)
          const data = craftData.map((e) => ({ ...e, isComplete: e.percentage === 100 }))
          setCraftData([
            ...data.filter((e) => e.count > latestCraftCount),
            ...data.filter((e) => e.count <= latestCraftCount),
          ])
        } else {
          for (let i = 0; i < craftData.length; i++) {
            const data = craftData[i]!
            if (data.count <= craftCount) {
              setCurrentRecord(craftData?.[i + 1]?.id ?? null)
            } else {
              // 判定が終わったので抜ける
              break
            }
          }
          LocalStorageService.saveCraftCampaignDisplay(craftCount)
          setTimeout(() => {
            setCraftData(craftData.map((e) => ({ ...e, isComplete: e.percentage === 100 })))
          }, 500)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible, selectTab])

  useEffect(() => {
    if (playBaseData && craftBaseData && !window.sessionStorage.getItem('PlayAndCraftCampaginChecked')) {
      props.setVisible(true)
      window.sessionStorage.setItem('PlayAndCraftCampaginChecked', new Date().toISOString())
    }

    if (playBaseData) {
      const latestPlayCount = LocalStorageService.loadPlayCampaignDisplay()
      const data = campaignConfig.playCountDatas.map((e) => ({
        id: `playCount_${e.imageNum}`,
        label: `${playCount < e.playCount ? playCount : e.playCount}/${e.playCount}`,
        percentage: 100 * Math.min(1, playCount / e.playCount),
        image: `/images/playAndCraftMission/gameplay/gameplay_${e.imageNum}.png`,
        completeImage: `/images/playAndCraftMission/gameplay/gameplay_${e.imageNum}_on.png`,
        count: e.playCount,
        // 読み込み済みのものはComplete画像重ねる
        isComplete: e.playCount <= latestPlayCount,
      }))
      setPlayCountData([
        ...data.filter((e) => e.count > latestPlayCount),
        ...data.filter((e) => e.count <= latestPlayCount),
      ])
    }

    if (craftBaseData) {
      const latestCraftCount = LocalStorageService.loadCraftCampaignDisplay()
      const data = campaignConfig.craftDatas.map((e) => ({
        id: `craft_${e.imageNum}`,
        label: `${craftCount < e.craftCount ? craftCount : e.craftCount}/${e.craftCount}`,
        percentage: 100 * Math.min(1, craftCount / e.craftCount),
        image: `/images/playAndCraftMission/craft/craft_${e.imageNum}.png`,
        completeImage: `/images/playAndCraftMission/craft/craft_${e.onImageNum}_on.png`,
        count: e.craftCount,
        // 読み込み済みのものはComplete画像重ねる
        isComplete: e.craftCount <= latestCraftCount,
      }))
      setCraftData([
        ...data.filter((e) => e.count > latestCraftCount),
        ...data.filter((e) => e.count <= latestCraftCount),
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playBaseData, craftBaseData])

  return {
    withinCampaign: now >= campaignConfig.startDate && campaignConfig.endDate >= now,
    visible: props.visible,
    selectTab,
    playCountData,
    isPlayCountCompleted: campaignConfig.maxPlayCount <= playCount,
    craftData,
    isCraftCompleted: campaignConfig.maxCraftCount <= craftCount,
    onClickClose: () => props.setVisible(false),
    onClickOpen: () => props.setVisible(true),
    onClickCampaginDetail: () => {
      window.open(campaignConfig.detailUrl)
    },
    onClickTab: (tab: Props['selectTab']) => {
      setSelectTab(tab)
    },
    currentRecord,
  }
}
